<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto api-header-wrapper">
        <h1>API</h1>
        <button class="api-link-button" @click="sendToApi">
          {{ $t("api.tech_doc") }}
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.7 0C7.39624 0 7.15 0.246243 7.15 0.55C7.15 0.853757 7.39624 1.1 7.7 1.1H9.12218L4.28609 5.93609C4.0713 6.15088 4.0713 6.49912 4.28609 6.71391C4.50088 6.9287 4.84912 6.9287 5.06391 6.71391L9.9 1.87782V3.3C9.9 3.60376 10.1462 3.85 10.45 3.85C10.7538 3.85 11 3.60376 11 3.3V0.55C11 0.246243 10.7538 0 10.45 0H7.7ZM1.1 1.65C1.1 1.34624 1.34624 1.1 1.65 1.1H4.4C4.70376 1.1 4.95 0.853757 4.95 0.55C4.95 0.246243 4.70376 0 4.4 0H1.65C0.73873 0 0 0.73873 0 1.65V9.35C0 10.2613 0.73873 11 1.65 11H9.35C10.2613 11 11 10.2613 11 9.35V6.56333C11 6.25958 10.7538 6.01333 10.45 6.01333C10.1462 6.01333 9.9 6.25958 9.9 6.56333V9.35C9.9 9.65376 9.65376 9.9 9.35 9.9H1.65C1.34624 9.9 1.1 9.65376 1.1 9.35V1.65Z"
              fill="#317475"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="sep"></div>
    <div class="row mt-3">
      <div class="col-12 col-xl-7">
        <div class="form-item pb-0">
          <div class="form-item__label">
            {{ $t("api.key_name") }}
          </div>
          <div class="form-item__field">
            <input
              ref="name"
              v-model="key_name"
              type="text"
              :class="{ invalid: $v.key_name.$error }"
              :placeholder="formatLabel('api.arbitrary_name')"
            />
            <small
              v-if="
                $v.key_name.$error &&
                (!$v.key_name.minLength || key_name === '')
              "
              class="error"
            >
              {{ $t("min_len_pass", { number: 4 }) }}
            </small>
            <small
              v-if="$v.key_name.$error && !$v.key_name.maxLength"
              class="error"
            >
              {{ $t("max_len_pass", { number: 64 }) }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-7">
        <div class="form-item pb-0">
          <div class="form-item__field">
            <div class="row align-items-center pt-2">
              <div class="col-12 col-md-4 mb-4">
                <button
                  :disabled="!key_name.length || $v.$invalid"
                  @click.prevent="generateKey"
                  class="btn w-100"
                >
                  {{ $t("api.generate") }}
                </button>
              </div>
              <div class="col-10 col-md-7 mb-4">
                <div class="message">
                  <div class="message__item icon-info _gray _bg-off mb-0">
                    <svg
                      class="message-icon"
                      v-svg
                      symbol="info"
                      size="0 0 17 17"
                      role="info"
                    />
                    <p>
                      {{ $t("api.generate_info") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-table mt-5 mt-md-0 col-12">
        <div class="ineer">
          <vue-good-table
            styleClass="table  _no-wrap"
            :columns="columns"
            :rows="rows"
          >
            <template slot="table-column" slot-scope="props">
              <span
                class="cursor-pointer"
                v-show="props.column.field !== 'control'"
              >
                {{ formatLabel(props.column.label) }}:
              </span>
            </template>
            <template slot="table-row" slot-scope="props">
              <template v-if="props.column.field === 'name'">
                <span class="api-table-name">
                  {{ props.row.name }}
                </span>
              </template>
              <template v-if="props.column.field === 'token'">
                <span class="api-table-key">
                  {{ props.row.token }}
                </span>
              </template>
              <template v-if="props.column.field === 'control'">
                <div class="controls-wrapper">
                  <button
                    @click="deleteApi(props.row.id)"
                    class="controls__btn"
                  >
                    <svg v-svg symbol="delete" size="0 0 15 15" role="info" />
                  </button>
                </div>
              </template>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <notifications group="message" />
  </div>
</template>

<script>
import apiService from "@/api/apiService";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
export default {
  name: "ApiKeys",
  mixins: [validationMixin],
  data() {
    return {
      key_name: "",
      columns: [
        {
          label: "auth.name",
          field: "name",
          sortable: false,
        },
        {
          label: "token",
          field: "token",
          sortable: true,
        },
        {
          label: "",
          field: "control",
          width: "170px",
          sortable: false,
        },
      ],
      rows: null,
    };
  },
  validations: {
    key_name: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(64),
    },
  },
  watch: {
    key_name() {
      this.$v.$touch();
    },
  },
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags"
    }),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const featureFlags = vm.$store.getters['user/getFeatureFlags'];
      if (
        featureFlags &&
        featureFlags.some((el) => el === "openapi-for-users")
      ) {
        next();
      } else {
        next({ name: "Dashboard" });
      }
    });
  },
  mounted() {
    this.getApi();
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
    deleteApi(id) {
      const payload = {
        id: id,
      };
      apiService.deleteApi(payload).then((res) => {
        if (res && res.status === 200)
          this.$notify({
            group: "message",
            ignoreDuplicates: true,
            title: this.$t("delete_successfully"),
          });
          this.getApi();
      });
    },
    generateKey() {
      const payload = {
        name: this.key_name,
      };
      apiService.setApi(payload).then((res) => {
        if(res && res.status === 200) {
          this.getApi();
        }
         else if(res && res.status === 400) {
          this.$notify({
            group: "message",
            type: "error",
            ignoreDuplicates: true,
            title: res.message,
          });
         }
      });
    },
    getApi() {
      apiService.getApi().then((res) => {
        this.rows = res;
      });
    },
    sendToApi() {
      const url = "https://api.partners.house";
      window.open(url, "_blank");
    }
  },
};
</script>

<style lang="scss">
.api-header-wrapper {
  display: flex;
  gap: 30px;
  align-items: center;

  h1 {
    margin: 0;
    padding: 0;
  }

  .api-link-button {
    display: flex;
    gap: 8px;
    background-color: rgba($color: #50b0b2, $alpha: 0.1);
    border-radius: 25px;
    padding: 12px 20px;
    color: #317475;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
  }
}

.message p {
  text-wrap: balance;
}

.controls-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button {
    background: transparent;

    &:focus {
      outline: none;
    }
  }
}

.api-table-name {
  color: #141414;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.1;
}

.api-table-key {
  color: #317475;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.1;
}

.message-icon {
  position: absolute;
  left: 0;
  fill: #9b9ba4;
}
</style>